import uniqid from "uniqid";
import { GrClose } from "react-icons/gr";
import { CgMenuGridR } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { MENU } from "../../../constants/menus";
import { POTREE_DISPLAY_MODES } from "../../../constants/potree";
import { OrtoSceneHelper } from "../../../utils/OrtoSceneHelper";
import { potreeTools } from "../../../utils/potreeTools";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import ToolMenu from "../shared/ToolMenu";
import Wrapper from "../shared/Wrapper";
import ToolButton from "../shared/ToolButton";
import AddScene from "../scenes/AddScene";

const TabsBar = () => {
  const { t } = useTranslation();
  const { project, potreeViewer, setProjectPcs } = usePotree();
  const { potreeDisplayMode, setPotreeDisplayMode, setActiveScene } =
    useToggle();

  // On project load or potree display mode change, set
  // first scene of display mode as active
  useEffect(() => {
    if (!project || !potreeViewer) return;

    // Find action method showModel, showGrundriss or showSchnitt
    // depending on currently active display mode
    const activeDisplayModeAction = POTREE_DISPLAY_MODES.find(
      ({ value }) => value === potreeDisplayMode
    )?.action;

    // Set display mode related default configs for camera prospective
    potreeTools[activeDisplayModeAction as string](potreeViewer);

    // Scene of currently active display mode
    const activeDisplayModeScenes =
      project?.field_scenes[potreeDisplayMode]?.scenes;

    // Check if there is a first scene in currently active display mode scenes
    const firstScene = activeDisplayModeScenes?.length
      ? activeDisplayModeScenes[0]
      : null;

    if (firstScene) {
      OrtoSceneHelper.setScene(potreeViewer, firstScene, setProjectPcs);
      setActiveScene(firstScene.id);
    } else {
      setActiveScene(null);
    }
  }, [project, potreeDisplayMode, potreeViewer, setProjectPcs, setActiveScene]);

  if (!potreeViewer) return null;

  return (
    <Wrapper
      elementType="section"
      classNames="z-10 absolute bottom-6 left-1/2 transform -translate-x-1/2"
    >
      <div className="flex flex-row gap-3">
        <ul className="flex flex-row items-center gap-3 rounded-lg p-2 bg-[#F5F5F5]">
          {POTREE_DISPLAY_MODES.map((displayMode) => {
            const label = POTREE_DISPLAY_MODES.find(
              ({ value }) => value === displayMode.value
            )?.value;

            return (
              <li
                key={uniqid()}
                onClick={() => setPotreeDisplayMode(displayMode.value)}
                className={`capitalize transition cursor-pointer px-4 py-2 rounded-lg ${
                  potreeDisplayMode === displayMode.value
                    ? "bg-primary text-white"
                    : "text-[#CDCDCD] hover:bg-lgray hover:text-white"
                }`}
              >
                {t(`${label}`)}
              </li>
            );
          })}
        </ul>

        <MeasurementMenu />
      </div>
    </Wrapper>
  );
};

const MeasurementMenu = () => {
  const { isRightSideMenuOpen } = useToggle();

  const getToolMenuButtonsList = (
    buttons: {
      toolName: string;
      action: string | null;
      toolIconSrc: string;
    }[]
  ) =>
    buttons.map((button, index) => (
      <ToolButton
        key={`tool-menu_${button.toolName}_${index}`}
        toolName={button.toolName}
        action={button.action}
        toolIconSrc={button.toolIconSrc}
      />
    ));

  return (
    <Wrapper classNames={`relative flex items-center text-primary`}>
      <MenuToggle />

      <Wrapper
        classNames={`absolute left-6 bottom-6 w-[220px] h-[220px] rounded-full items-center justify-center flex transition-all transform
        ${
          isRightSideMenuOpen
            ? "translate-x-0"
            : "scale-0 -translate-x-[50%] translate-y-[50%] h-0 w-0"
        }
        `}
      >
        <ToolMenu
          menuLabel="measure"
          children={getToolMenuButtonsList(MENU.Measure)}
          classNames={`fixed top-0`}
        />
        <ToolMenu
          menuLabel="view"
          children={getToolMenuButtonsList(MENU.View)}
          classNames={`fixed left-0 top-1/2 -translate-x-1/2 -translate-y-1/2`}
        />
        <ToolMenu
          menuLabel="slides"
          children={getToolMenuButtonsList(MENU.Slides)}
          classNames={`fixed`}
        />
        <ToolMenu
          menuLabel="component"
          // children={<AddScene />}
          children={getToolMenuButtonsList(MENU.Component)}
          classNames={`fixed right-0 top-1/2 translate-x-1/2 -translate-y-1/2`}
        />
        <ToolMenu
          menuLabel="navigate"
          children={getToolMenuButtonsList(MENU.Navigate)}
          classNames={`fixed bottom-0 right-0`}
        />
      </Wrapper>
    </Wrapper>
  );
};

const MenuToggle = () => {
  const { isRightSideMenuOpen, setIsRightSideMenuOpen, potreeDisplayMode } =
    useToggle();

  return (
    <button
      onClick={() => setIsRightSideMenuOpen(() => !isRightSideMenuOpen)}
      className={`group fixed z-10 flex items-center gap-8 p-4 rounded-lg transform transition bg-[#F5F5F5] text-primary`}
    >
      {isRightSideMenuOpen ? (
        <GrClose
          size={24}
          className={`group-hover:scale-125 transition-all 
            `}
        />
      ) : (
        <CgMenuGridR
          size={24}
          className={`group-hover:scale-125 transition-all 
            `}
        />
      )}
    </button>
  );
};

export default TabsBar;
